<script>
import GenericSlider from '@/components/general/GenericSlider'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import EmptyList from '@/components/general/EmptyList.vue'
export default {
  name: 'InternalMobility',
  components: {
    GenericSlider,
    Breadcrumbs,
    EmptyList
  },
  data () {
    return {
      positions: [],
      inprogressApplications: [],
      concludedApplications: [],
      isLoading: true,
      isEmpty: false
      // menuOptions: [
      //   { to: { name: 'business.internal.positions.incompleted' }, text: 'internal.mobility:incompleted', icon: 'mdi-list-status' },
      //   { to: { name: 'business.internal.positions.under.analysis' }, text: 'internal.mobility:under.analysis', icon: 'mdi-clipboard-text-outline' },
      //   { to: { name: 'business.internal.positions.finished' }, text: 'internal.mobility:finished', icon: 'mdi-clipboard-check' }
      // ],
    }
  },
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'main.header:internal.positions', href: this.$route.path }]
      return path
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('attemptGetPositionsByCompany', this.selectedWorkspace.id),
      this.$store.dispatch('attemptGetAllApplications', this.selectedWorkspace.name.toLowerCase())
    ]).then(([data1, data2]) => {
      const applications = data2.map(item => item.position)
      this.inprogressApplications = applications.filter(a => a.status !== 'no_filter' && a.company.subdomain === this.selectedWorkspace.subdomain).sort((a, b) => a.title.localeCompare(b.title))
      this.concludedApplications = applications.filter(a => a.status === 'no_filter' && a.company.subdomain === this.selectedWorkspace.subdomain).sort((a, b) => a.title.localeCompare(b.title))
      this.positions = data1
      if (this.positions.length > 0) this.isEmpty = true
      this.isLoading = false
    })
  }
}
</script>
<template>
  <section class="internal-mobility--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ $t('internal.mobility:title') }}</h2>
    </div>
    <div class="internal-mobility--body">
      <h5 v-if="!positions.length">{{ $t('internal.mobility:open.positions') }}</h5>
      <div class="skeleton" v-if="isLoading">
        <v-skeleton-loader class="skeleton-card" type="image, article" width="295px" height="385px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
      <div v-else>
        <GenericSlider v-if="positions.length" title="internal.mobility:open.positions" :items="positions"
          type="positions" class="generic-slider" />
        <empty-list v-else :title="$t('internal.mobility:without.positions')" :image="'/assets/images/empty-box.png'"></empty-list>
      </div>
    </div>
    <div class="candidature--header-wrapper" v-if="!isLoading">
      <div class="candidature--header">
        <h5 class="lato">{{ $t('candidature.list:closed.title') }}</h5>
        <p class="lato" :class="{ 'title-warning': false }">{{ $t('candidature.list:closed.view.subtitle') }}</p>
        <v-btn class="bold btn transform-unset"
          :to="{ name: (selectedWorkspace.type + '.candidature.closed'), params: { companyDomain: selectedWorkspace.subdomain } }"
          :color="getPrimaryColor" dark><v-icon size="18" color="#FFFFFF" class="mr-2">mdi-open-in-new</v-icon>{{
  $t('candidature.list:closed.view')
          }}</v-btn>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
.internal-mobility--container {
  .internal-mobility--body {

    h5 {
      margin-top: -30px;
      font-size: 16px;
      line-height: 52px;
    }

    .generic-slider {

      h5{
      margin-top: 5px;
      }
      margin-top: -35px;
    }

    .internal-mobility--menu-title {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: #212121;
      margin-top: 80px;
    }

    .horizontal-menu--container {
      margin-top: 8px;
      margin-bottom: 12px;
    }

    .skeleton {
      position: absolute;
      top: 245px;
      display: flex;

      .skeleton-card {
        margin-right: 20px;
      }
    }

  }

  .candidature--header-wrapper {
    .v-btn {
      margin-bottom: 50px;
      font-weight: bold;

      .v-icon {
        margin-left: 0px;
      }
    }

    .candidature--header {
      h5 {
        font-size: 16px;
        color: #242321;
      }

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #7E7D77;

        &.title-warning {
          color: #FF8888;
        }
      }
    }
  }
}
</style>
